import React from 'react';
import { graphql } from 'gatsby';
import HTMLReactParser from 'html-react-parser';

import Layout from '../layouts';
import Container from '../components/Container';

import loadComponents from '../components/Loadable';

const BlogLayout = loadComponents('blog-layout');
const SubpageHeader = loadComponents('subpage-header');

const { BlogTitle, BlogUrl } = require('../utils/constants');

export default function CategoryTemplate({ location, data }) {
  const prevPage = location.state && location.state.prevPage;

  const { wpCategory, allWpPost } = data;

  const pageTitle =
    wpCategory.seo && wpCategory.seo.title
      ? wpCategory.seo.title
      : wpCategory.name;

  return (
    <Layout
      title={pageTitle}
      seo={wpCategory.seo}
      location={wpCategory.link}
      prevPage={prevPage}
      padding="0 0 60px"
    >
      <article>
        <SubpageHeader
          title={wpCategory.name}
          link={wpCategory.link}
          parent={{
            title: BlogTitle,
            link: `/${BlogUrl}/`,
          }}
        />
        <Container>
          {wpCategory.description && (
            <div style={{ marginBottom: `60px` }}>
              {HTMLReactParser(wpCategory.description)}
            </div>
          )}
          <BlogLayout allWpPost={allWpPost} />
        </Container>
      </article>
    </Layout>
  );
}

export const categoryQuery = graphql`
  query ($id: String!) {
    wpCategory(id: { eq: $id }) {
      name
      description
      link
      ...CategorySeo
    }
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          excerpt
          ...PostData
          ...BlogThumbnail
        }
      }
    }
  }
`;
